import { Form, Input, Button, message } from "antd";
import firebase from "firebase/app";
import React from "react";
import { useHistory } from "react-router";

function Login() {
  const [pensando, setPensando] = React.useState(false);
  const history = useHistory();
  function loguearse(values) {
    setPensando(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then((userCredential) => {
        history.push("/noticias");
        setPensando(false);
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        message.error("No pudimos loguearte");
        setPensando(false);
      });
  }
  return (
    <div>
      <Form onFinish={loguearse} layout="vertical" style={{ maxWidth: 300 }}>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Campo obligatorio" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Contraseña"
          rules={[{ required: true, message: "Campo obligatorio" }]}
        >
          <Input.Password />
        </Form.Item>
        <Button loading={pensando} htmlType="submit">
          Ingresar
        </Button>
      </Form>
    </div>
  );
}

export default Login;
