import VerificarLogin from "../componentes/verificarLogin";
import {
  Button,
  Drawer,
  Input,
  Table,
  Form,
  message,
  Popconfirm,
  DatePicker,
  Select,
} from "antd";
import React from "react";
import firebase from "firebase/app";
import moment from "moment";
import "moment/locale/es";

const { Option } = Select;

function Seguridad() {
  const [visible, setVisible] = React.useState(false);
  const [pensando, setPensando] = React.useState(false);
  const [dataSource, setDataSource] = React.useState([]);
  const [urlImagen, setUrlImagen] = React.useState("");
  const categorias = {
    cai: "Líneas telefónicas y dirección de los cuadrantes",
    emergencia: "Líneas telefónicas y dirección de emergencia",
    alcaldia: "Líneas de interés y dirección de la alcaldía local de fontibon",
    secretaria: "Ruta RITA y Ruta de secretaría distrital de gobierno",
  };
  async function eliminarContacto(im) {
    await firebase.firestore().collection("seguridad").doc(im).delete();
    Get();
  }

  function Get() {
    firebase
      .firestore()
      .collection("seguridad")
      .orderBy("creado", "desc")
      .limit(100)
      .get()
      .then((re) => {
        const v = [];
        re.forEach((doc) => {
          v.push({ ...doc.data(), id: doc.id });
        });
        setDataSource(v);
      })
      .catch((e) => {});
  }
  React.useEffect(() => {
    Get();
  }, []);

  function crearContacto(values) {
    setPensando(true);
    firebase
      .firestore()
      .collection("seguridad")
      .add({
        titulo: values.titulo,
        categoria: values.categoria,
        telefono1: values.telefono1,
        telefono2: values.telefono2 || "",
        telefono3: values.telefono3 || "",
        creado: new Date(),
      })
      .then((e) => {
        setPensando(false);
        setVisible(false);
        Get();
        message.success("Contacto Creado");
      })
      .catch((e) => {
        setPensando(false);
        message.error("Algo no salio bien");
      });
  }

  const columns = [
    {
      title: "Titulo",
      dataIndex: "titulo",
      key: "titulo",
    },
    {
      title: "Categoria",
      dataIndex: "categoria",
      key: "categoria",
      render: (cate) => <span>{categorias[cate]}</span>,
    },
    {
      title: "Telefono",
      dataIndex: "telefono1",
      key: "telefono1",
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <Popconfirm
          title="Eliminar este contacto"
          onConfirm={() => {
            eliminarContacto(id);
          }}
        >
          <strong style={{ cursor: "pointer" }}>X</strong>
        </Popconfirm>
      ),
    },
  ];
  return (
    <>
      <VerificarLogin />
      <Button
        onClick={() => {
          setVisible(true);
        }}
        style={{ marginBottom: 10 }}
      >
        Nuevo Contacto
      </Button>
      <Drawer
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        title="Nuevo  Contacto"
      >
        <Form layout="vertical" onFinish={crearContacto}>
          <Form.Item
            name="categoria"
            label="Categoria"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Select>
              <Option value="cai">
                Líneas telefónicas y dirección de los cuadrantes
              </Option>
              <Option value="emergencia">
                Líneas telefónicas y dirección de emergencia
              </Option>
              <Option value="alcaldia">
                Líneas de interés y dirección de la alcaldía local de fontibon
              </Option>
              <Option value="secretaria">
                Ruta RITA y Ruta de secretaría distrital de gobierno
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="titulo"
            label="Titulo"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="telefono1"
            label="Teléfono 1"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="telefono2" label="Teléfono 2">
            <Input />
          </Form.Item>
          <Form.Item name="telefono3" label="Teléfono 3">
            <Input />
          </Form.Item>

          <Button
            style={{ marginTop: 20 }}
            loading={pensando}
            htmlType="submit"
          >
            Guardar
          </Button>
        </Form>
      </Drawer>
      <Table dataSource={dataSource} columns={columns} />
    </>
  );
}
export default Seguridad;
