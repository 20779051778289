import firebase from "firebase/app";
import { Form, Input, AutoComplete, Button, message, Spin } from "antd";
import usePlacesAutocomplete from "@atomap/use-places-autocomplete";

import React from "react";

function FormularioRegistro(props) {
  const [cargando, setCargando] = React.useState(true);
  const [imagen, SetImagen] = React.useState();
  const [form] = Form.useForm();
  const [searchValue, setSearchValue] = React.useState("");
  const [direcciones, setDirecciones] = React.useState([]);
  const { predictions, error } = usePlacesAutocomplete(searchValue, {
    componentRestrictions: { country: "co" },
  });
  const [pensando, setPensando] = React.useState(false);

  if (error) {
    console.error(error);
  }

  const imagenSeleccionada = (event) => {
    SetImagen(event.target.files[0]);
  };
  React.useEffect(() => {
    if (predictions) {
      const em = [...predictions];
      em.forEach((e) => {
        e.value = e.description;
      });
      setDirecciones(em);
    }
  }, [predictions]);
  React.useEffect(() => {
    const usuario = firebase.auth().currentUser;

    if (usuario) {
      if (props.id) {
        buscarDirecciones(props.id.direccion);
        form.setFieldsValue(props.id);
      }
      return setCargando(false);
    }
    firebase
      .auth()
      .signInAnonymously()
      .then((e) => {
        setCargando(false);
      });
  }, [props.id]);
  async function buscarDirecciones(e) {
    setSearchValue(e);
  }

  async function crearNegocio(values) {
    setPensando(true);

    setTimeout(() => {
      const direccion = predictions.find(
        (e) => e.description === values.direccion
      );
      if (!direccion) {
        setCargando(false);
        setPensando(false);
        return message.error(
          "No encontramos la dirección del negocio. Selecciona una de las opciones"
        );
      }

      var pathImagen;
      if (!props.id) {
        const UidUsuario = firebase.auth().currentUser.uid;
        var storage = firebase.storage();
        var storageRef = storage.ref();
        const pathImagen = `vitrina/${UidUsuario}/${new Date().getMilliseconds()}${
          imagen.name
        }`;
        var imga = storageRef.child(pathImagen);
        var file = imagen; // use the Blob or File API
        imga
          .put(file)
          .then(function (snapshot) {
            snapshot.ref.getDownloadURL().then(function (downloadURL) {
              guardar(downloadURL);
            });
          })
          .catch((e) => {
            message.error("Algo no salio bien");
          });
      } else {
        pathImagen = props.id.pathImage;
        guardar(props.id.foto);
      }

      async function guardar(downloadURL) {
        new window.google.maps.places.PlacesService(
          document.createElement("div")
        ).getDetails(
          {
            placeId: direccion.place_id,
          },
          async (data, status) => {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
              setCargando(false);
              setPensando(false);
              message.error("Algo no salio bien");
            }

            values.localizacion = {
              lat: data.geometry.location.lat(),
              lng: data.geometry.location.lng(),
            };
            values.imagen = downloadURL;
            values.pathImage = pathImagen;

            if (props.id) {
              values.imagen = props.id.imagen;
              values.pathImage = props.id.pathImage;
              values.creado = new Date();
              try {
                await firebase
                  .firestore()
                  .collection("empresas")
                  .doc(props.id.id)
                  .update(values);
                setCargando(false);
                setPensando(false);
                message.success("Empresa actualizada");
              } catch (error) {
                setCargando(false);
                setPensando(false);
                message.error("Algo no salio bien");
                console.log(error);
              }
            } else {
              try {
                var addMessage = firebase.functions().httpsCallable("registro");
                addMessage(values)
                  .then((result) => {
                    setCargando(false);
                    setPensando(false);
                    message.success("Empresa registrada");
                  })
                  .catch((e) => {
                    setCargando(false);
                    setPensando(false);
                    message.error("Algo no salio bien");
                  });
              } catch (error) {
                setCargando(false);
                setPensando(false);
                message.error("Algo no salio bien");
              }
            }
          }
        );
      }
    }, 1000);
  }

  return (
    <div>
      {cargando ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            width: "100%",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <span>Cargando</span>
          <span>Espere un momento</span>
          <Spin />
        </div>
      ) : (
        <Form form={form} onFinish={crearNegocio} layout="vertical">
          <Form.Item
            rules={[{ required: true, message: "Campo obligatorio" }]}
            name="nombreEncargado"
            label="Nombre Encargado"
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Campo obligatorio" }]}
            name="nombreNegocio"
            label="Nombre Negocio"
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Campo obligatorio" }]}
            name="descripcion"
            label="Descripción del Negocio"
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Campo obligatorio" }]}
            name="telefono"
            label="Teléfono del negocio"
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Campo obligatorio" }]}
            name="direccion"
            label="Dirección del negocio"
          >
            {/* <Input
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            /> */}
            <AutoComplete
              defaultActiveFirstOption={true}
              options={direcciones}
              onSearch={(e) => {
                setSearchValue(e);
              }}
              notFoundContent={null}
            />
          </Form.Item>
          <Form.Item name="detallesDireccion" label="Direccion detalles">
            <Input placeholder="coloque si se requiere el numero del local o indicaciones extra" />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Campo obligatorio" }]}
            name="keywords"
            label="¿Cómo te encuentran?"
          >
            <Input placeholder="Divide con coma. Panaderia, ferreteria, peluqueria " />
          </Form.Item>
          {!props.id && (
            <Form.Item
              rules={[{ required: true, message: "Campo obligatorio" }]}
              name="foto"
              label="Foto del local"
            >
              <Input onChange={imagenSeleccionada} type="file" />
            </Form.Item>
          )}

          <Form.Item name="web" label="Sitio Web">
            <Input type="url" />
          </Form.Item>
          <Form.Item name="facebook" label="Link Facebook">
            <Input type="url" />
          </Form.Item>
          <Form.Item name="instagram" label="Link Instagram">
            <Input type="url" />
          </Form.Item>
          <Form.Item name="youtube" label="Link Youtube">
            <Input type="url" />
          </Form.Item>
          <Form.Item name="twitter" label="Link Twitter">
            <Input type="url" />
          </Form.Item>

          <Button htmlType="submit" loading={pensando}>
            Enviar
          </Button>
        </Form>
      )}
    </div>
  );
}

export default FormularioRegistro;
