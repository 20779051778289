import React from "react";
import firebase from "firebase";
import { useHistory } from "react-router";

function VerificarLogin() {
  const history = useHistory();
  React.useEffect(() => {
    const user = firebase.auth().currentUser;
    if (!user) {
      history.push("/login");
    }
  }, []);

  return <></>;
}

export default VerificarLogin;
