import VerificarLogin from "../componentes/verificarLogin";
import {
  Button,
  Drawer,
  Input,
  Table,
  Form,
  message,
  Popconfirm,
  DatePicker,
} from "antd";
import React from "react";
import firebase from "firebase/app";
import moment from "moment";
import "moment/locale/es";

function Eventos() {
  const [visible, setVisible] = React.useState(false);
  const [pensando, setPensando] = React.useState(false);
  const [dataSource, setDataSource] = React.useState([]);
  const [imagen, SetImagen] = React.useState("");
  const [urlImagen, setUrlImagen] = React.useState("");

  const imagenSeleccionada = (event) => {
    SetImagen(event.target.files[0]);
    const imagen = window.URL.createObjectURL(event.target.files[0]);
    setUrlImagen(imagen);
  };

  async function eliminarEvento(im, noti) {
    var storage = firebase.storage();
    var storageRef = storage.ref();
    var mountainsRef = storageRef.child(noti.pathImagen);
    mountainsRef.delete();
    await firebase.firestore().collection("eventos").doc(im).delete();
    Get();
  }

  function Get() {
    firebase
      .firestore()
      .collection("eventos")
      .orderBy("creado", "desc")
      .limit(100)
      .get()
      .then((re) => {
        const v = [];
        re.forEach((doc) => {
          v.push({ ...doc.data(), id: doc.id });
        });
        setDataSource(v);
      })
      .catch((e) => {});
  }
  React.useEffect(() => {
    Get();
  }, []);

  function crearEvento(values) {
    if (imagen === "") return message.error("La imagen es obligatoria");
    setPensando(true);
    var storage = firebase.storage();
    var storageRef = storage.ref();
    const pathImagen = `admin/${new Date().getMilliseconds()}${imagen.name}`;
    var mountainsRef = storageRef.child(pathImagen);
    var file = imagen; // use the Blob or File API
    mountainsRef
      .put(file)
      .then(function (snapshot) {
        snapshot.ref.getDownloadURL().then(function (downloadURL) {
          firebase
            .firestore()
            .collection("eventos")
            .add({
              titulo: values.titulo,
              descripcion: values.descripcion,
              link: values.link || "" ,
              lugar: values.lugar,
              cuando: moment(values.cuando.toString())
                .locale("es")
                .format("LLL"),
              creado: new Date(),
              fecha: new Date(values.cuando.toString()),
              imagen: downloadURL,
              pathImagen,
            })
            .then((e) => {
              setPensando(false);
              setVisible(false);
              Get();
              message.success("Evento Creado");
            })
            .catch((e) => {
              setPensando(false);
              message.error("Algo no salio bien");
            });
        });
      })
      .catch((e) => {
        message.error("Algo no salio bien");
      });
  }

  const columns = [
    {
      title: "Titulo",
      dataIndex: "titulo",
      key: "titulo",
    },
    {
      title: "Lugar",
      dataIndex: "lugar",
      key: "lugar",
    },
    {
      title: "Cuando",
      dataIndex: "cuando",
      key: "cuando",
    },

    {
      title: "Descripcion",
      dataIndex: "descripcion",
      key: "descripcion",
    },
    {
      title: "Link mas info",
      dataIndex: "link",
      key: "link",
    },
    {
      title: "Imagen",
      dataIndex: "imagen",
      key: "imagen",
      render: (imagen) => <img alt="imagen" src={imagen} width={100} />,
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id, noti) => (
        <Popconfirm
          title="Eliminar este eventos"
          onConfirm={() => {
            eliminarEvento(id, noti);
          }}
        >
          <strong style={{ cursor: "pointer" }}>X</strong>
        </Popconfirm>
      ),
    },
  ];
  return (
    <>
      <VerificarLogin />
      <Button
        onClick={() => {
          setVisible(true);
        }}
        style={{ marginBottom: 10 }}
      >
        Crear Evento
      </Button>
      <Drawer
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        title="Crear Evento"
      >
        <Form layout="vertical" onFinish={crearEvento}>
          <Form.Item
            name="titulo"
            label="Titulo"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lugar"
            label="Lugar"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="cuando"
            label="Cuando"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <DatePicker
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
          <Form.Item
            name="descripcion"
            label="Descripcion"
            rules={[
              { required: true, message: "Campo obligatorio" },
              { min: 100, message: "Minimo 100 caracteres" },
            ]}
          >
            <Input.TextArea min={100} />
          </Form.Item>

          <Form.Item name="link" label="Link">
            <Input type="url" />
          </Form.Item>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {urlImagen && <img alt="imagen" src={urlImagen} width={200} />}
            <label for="file-upload" class="custom-file-upload">
              Subir Imagen
            </label>
            <input onChange={imagenSeleccionada} id="file-upload" type="file" />
          </div>

          <Button
            style={{ marginTop: 20 }}
            loading={pensando}
            htmlType="submit"
          >
            Guardar
          </Button>
        </Form>
      </Drawer>
      <Table dataSource={dataSource} columns={columns} />
    </>
  );
}
export default Eventos;
