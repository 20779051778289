import VerificarLogin from "../componentes/verificarLogin";
import {
  Button,
  Drawer,
  Input,
  Table,
  Form,
  message,
  Popconfirm,
  Image,
  Space,
  Spin,
} from "antd";
import React from "react";
import firebase from "firebase/app";
import moment from "moment";
import "moment/locale/es";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faLink,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faTwitch,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import FormularioRegistro from "../componentes/FormularioRegistro";

function Vitrina() {
  const [visible, setVisible] = React.useState(false);
  const [pensando, setPensando] = React.useState(false);
  const [cargando, setCargando] = React.useState(false);
  const [empresaSeleccionada, setEmpresaSeleccionada] = React.useState();
  const [dataSource, setDataSource] = React.useState([]);
  const [imagen, SetImagen] = React.useState("");
  const [urlImagen, setUrlImagen] = React.useState("");

  const imagenSeleccionada = (event) => {
    SetImagen(event.target.files[0]);
    const imagen = window.URL.createObjectURL(event.target.files[0]);
    setUrlImagen(imagen);
  };

  async function eliminarEmpresa(im, empres) {
    if (empres.pathImagen) {
      var storage = firebase.storage();
      var storageRef = storage.ref();
      var mountainsRef = storageRef.child(empres.pathImagen);
      mountainsRef.delete();
    }

    await firebase.firestore().collection("empresas").doc(im).delete();
    Get();
  }

  function Get() {
    firebase
      .firestore()
      .collection("empresas")
      .orderBy("creado", "desc")
      .get()
      .then((re) => {
        const v = [];
        re.forEach((doc) => {
          v.push({ ...doc.data(), id: doc.id });
        });
        console.log(v);
        setCargando(false);
        setDataSource(v);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  React.useEffect(() => {
    Get();
  }, []);

  async function SwitchPublicar(valor, empresa) {
    setCargando(true);
    await firebase.firestore().collection("empresas").doc(empresa).update({
      publicada: valor,
    });
    Get();
  }

  const columns = [
    {
      title: "Negocio",
      dataIndex: "nombreNegocio",
      key: "nombreNegocio",
    },
    {
      title: "Teléfono",
      dataIndex: "telefono",
      key: "telefono",
    },
    {
      title: "Direccion",
      dataIndex: "direccion",
      key: "direccion",
    },
    {
      title: "Foto Local",
      dataIndex: "imagen",
      key: "imagen",
      render: (imagen) => <Image alt="local" width={50} src={imagen} />,
    },
    {
      title: "Redes y Web",
      dataIndex: "redes",
      key: "redes",
      render: (imagen, doc) => (
        <Space>
          {doc.instagram.length > 0 && (
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(doc.instagram, "_blank");
              }}
              icon={faInstagram}
            />
          )}
          {doc.youtube.length > 0 && (
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(doc.youtube, "_blank");
              }}
              icon={faYoutube}
            />
          )}
          {doc.twitter.length > 0 && (
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(doc.twitter, "_blank");
              }}
              icon={faTwitter}
            />
          )}
          {doc.facebook.length > 0 && (
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(doc.facebook, "_blank");
              }}
              icon={faFacebook}
            />
          )}
          {doc.web.length > 0 && (
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(doc.web, "_blank");
              }}
              icon={faLink}
            />
          )}
        </Space>
      ),
    },
    {
      title: "Publicada",
      dataIndex: "publicada",
      key: "publicada",
      render: (publicada, empre) =>
        publicada ? (
          cargando ? (
            <Spin />
          ) : (
            <FontAwesomeIcon
              style={{ color: "green", cursor: "pointer" }}
              icon={faEye}
              onClick={() => {
                SwitchPublicar(false, empre.id);
              }}
            />
          )
        ) : cargando ? (
          <Spin />
        ) : (
          <FontAwesomeIcon
            style={{ color: "red", cursor: "pointer" }}
            icon={faEyeSlash}
            onClick={() => {
              SwitchPublicar(true, empre.id);
            }}
          />
        ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id, noti) => (
        <Space>
          <FontAwesomeIcon
            onClick={() => {
              noti.id = id;
              setVisible(true);
              setEmpresaSeleccionada(noti);
            }}
            style={{ cursor: "pointer" }}
            icon={faPencilAlt}
          />
          <Popconfirm
            title="Eliminar esta empresa"
            onConfirm={() => {
              eliminarEmpresa(id, noti);
            }}
          >
            <strong style={{ cursor: "pointer" }}>X</strong>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <>
      <VerificarLogin />
      <Button
        onClick={() => {
          setEmpresaSeleccionada("");
          setVisible(true);
        }}
        style={{ marginBottom: 10 }}
      >
        Crear Empresa
      </Button>
      <Drawer
        visible={visible}
        width={400}
        onClose={() => {
          Get();
          setVisible(false);
        }}
        title={empresaSeleccionada ? "Acualizar Empresa" : "Crear Empresa"}
      >
        <FormularioRegistro id={empresaSeleccionada} />
      </Drawer>
      <Table dataSource={dataSource} columns={columns} />
    </>
  );
}
export default Vitrina;
