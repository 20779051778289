import firebase from "firebase/app";
import { useHistory } from "react-router";
function Logout() {
  const history = useHistory();
  firebase.auth().signOut();
  history.push("/login");
  return <></>
}

export default Logout;
