import React from "react";
import firebase from "firebase/app";
import { Layout, Menu } from "antd";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import "antd/dist/antd.css";
import "./App.css";
import Noticias from "./pages/Noticias";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Eventos from "./pages/Eventos";
import Cultura from "./pages/Cultura";
import Registro from "./pages/Registro";
import Vitrina from "./pages/Vitrina";
import Seguridad from "./pages/Seguridad";
import Contactenos from "./pages/Contactenos";
import Banners from "./pages/Banners";

function App() {
  const { Header, Content, Footer } = Layout;
  const [usuarioAdmin, setUsuarioAdmin] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    console.log("Super interesante ante.");
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.uid === "c4qhf9K4q9dbvN9xMltyyUJLg5A2" && setUsuarioAdmin(true);
      } else {
        setUsuarioAdmin(false);
      }
    });
  }, []);

  return (
    <div>
      <Layout className="layout">
        <Header>
          <div className="logo" />
          {usuarioAdmin && (
            <Menu
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={[location.pathname]}
            >
              <Menu.Item key="/noticias">
                <Link to="/noticias">Noticias</Link>
              </Menu.Item>
              <Menu.Item key="/eventos">
                <Link to="/eventos">Eventos</Link>
              </Menu.Item>
              <Menu.Item key="/vitrina">
                <Link to="/vitrina">Vitrina</Link>
              </Menu.Item>
              <Menu.Item key="/cultura">
                <Link to="/cultura">Cultura</Link>
              </Menu.Item>
              <Menu.Item key="/seguridad">
                <Link to="/seguridad">Seguridad</Link>
              </Menu.Item>
              <Menu.Item key="/banners">
                <Link to="/banners">Banner</Link>
              </Menu.Item>
              <Menu.Item key="/contactenos">
                <Link to="/contactenos">Contactenos</Link>
              </Menu.Item>
              <Menu.Item>
                <Link
                  style={{ color: "white", fontWeight: "bold" }}
                  to="/logout"
                >
                  Cerrar Sesión
                </Link>
              </Menu.Item>
            </Menu>
          )}
        </Header>
        <Content style={{ padding: "0 50px" }}>
          <div className="site-layout-content">
            <Switch>
              <Route path="/login">
                <Login />
              </Route>

              <Route path="/noticias">
                <Noticias />
              </Route>
              <Route path="/cultura">
                <Cultura />
              </Route>
              <Route path="/eventos">
                <Eventos />
              </Route>
              <Route path="/vitrina">
                <Vitrina />
              </Route>
              <Route path="/logout">
                <Logout />
              </Route>
              <Route path="/seguridad">
                <Seguridad />
              </Route>
              <Route path="/banners">
                <Banners />
              </Route>
              <Route path="/contactenos">
                <Contactenos />
              </Route>
              <Route path="/registro">
                <Registro />
              </Route>
              <Route path="/">
                <Noticias />
              </Route>
            </Switch>
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Todos los derechos reservados
        </Footer>
      </Layout>
    </div>
  );
}

export default App;
