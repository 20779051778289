import VerificarLogin from "../componentes/verificarLogin";
import {
  Button,
  Drawer,
  Input,
  Table,
  Form,
  message,
  Popconfirm,
  Select,
} from "antd";
import React from "react";
import firebase from "firebase/app";
import "moment/locale/es";

const { Option } = Select;

function Contactenos() {
  const [visible, setVisible] = React.useState(false);
  const [pensando, setPensando] = React.useState(false);
  const [dataSource, setDataSource] = React.useState([]);
  const categorias = {
    cai: "Líneas telefónicas y dirección de los cuadrantes",
    emergencia: "Líneas telefónicas y dirección de emergencia",
    alcaldia: "Líneas de interés y dirección de la alcaldía local de fontibon",
    secretaria: "Ruta RITA y Ruta de secretaría distrital de gobierno",
  };
  async function eliminarContacto(im) {
    await firebase.firestore().collection("contactenos").doc(im).delete();
    Get();
  }

  function Get() {
    firebase
      .firestore()
      .collection("contactenos")
      .orderBy("creado", "desc")
      .limit(100)
      .get()
      .then((re) => {
        const v = [];
        re.forEach((doc) => {
          v.push({ ...doc.data(), id: doc.id });
        });
        setDataSource(v);
      })
      .catch((e) => {});
  }
  React.useEffect(() => {
    Get();
  }, []);

  function crearContacto(values) {
    setPensando(true);
    firebase
      .firestore()
      .collection("contactenos")
      .add({
        despacho: values.despacho,
        telefono: values.telefono,
        responsable: values.responsable,
        email: values.email,
        creado: new Date(),
      })
      .then((e) => {
        setPensando(false);
        setVisible(false);
        Get();
        message.success("Contacto Creado");
      })
      .catch((e) => {
        setPensando(false);
        message.error("Algo no salio bien");
      });
  }

  const columns = [
    {
      title: "Depacho",
      dataIndex: "despacho",
      key: "despacho",
    },
    {
      title: "Responsable",
      dataIndex: "responsable",
      key: "responsable",
    },
    {
      title: "Telefono",
      dataIndex: "telefono",
      key: "telefono",
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <Popconfirm
          title="Eliminar este contacto"
          onConfirm={() => {
            eliminarContacto(id);
          }}
        >
          <strong style={{ cursor: "pointer" }}>X</strong>
        </Popconfirm>
      ),
    },
  ];
  return (
    <>
      <VerificarLogin />
      <Button
        onClick={() => {
          setVisible(true);
        }}
        style={{ marginBottom: 10 }}
      >
        Nuevo Contacto
      </Button>
      <Drawer
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        title="Nuevo  Contacto"
      >
        <Form layout="vertical" onFinish={crearContacto}>
          <Form.Item
            name="despacho"
            label="Despacho"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Correo electrónico"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="telefono"
            label="Teléfono"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Campo obligatorio" }]}
            name="responsable"
            label="Responsable"
          >
            <Input />
          </Form.Item>

          <Button
            style={{ marginTop: 20 }}
            loading={pensando}
            htmlType="submit"
          >
            Guardar
          </Button>
        </Form>
      </Drawer>
      <Table dataSource={dataSource} columns={columns} />
    </>
  );
}
export default Contactenos;
