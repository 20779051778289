import VerificarLogin from "../componentes/verificarLogin";
import {
  Button,
  Drawer,
  Input,
  Table,
  Form,
  message,
  Popconfirm,
  DatePicker,
} from "antd";
import React from "react";
import firebase from "firebase/app";
import moment from "moment";
import "moment/locale/es";

function Cultura() {
  const [visible, setVisible] = React.useState(false);
  const [pensando, setPensando] = React.useState(false);
  const [dataSource, setDataSource] = React.useState([]);
  const [urlImagen, setUrlImagen] = React.useState("");

  async function eliminarEmpresa(im, noti) {
    // var storage = firebase.storage();
    // var storageRef = storage.ref();
    // var mountainsRef = storageRef.child(noti.pathImagen);
    // mountainsRef.delete();
    await firebase.firestore().collection("cultura").doc(im).delete();
    Get();
  }

  function Get() {
    firebase
      .firestore()
      .collection("cultura")
      .orderBy("creado", "desc")
      .limit(100)
      .get()
      .then((re) => {
        const v = [];
        re.forEach((doc) => {
          v.push({ ...doc.data(), id: doc.id });
        });
        setDataSource(v);
      })
      .catch((e) => {});
  }
  React.useEffect(() => {
    Get();
  }, []);

  function crearEmpresa(values) {
    setPensando(true);
    firebase
      .firestore()
      .collection("cultura")
      .add({
        titulo: values.titulo,
        descripcion: values.descripcion,
        creado: new Date(),
      })
      .then((e) => {
        setPensando(false);
        setVisible(false);
        Get();
        message.success("Empresa Creado");
      })
      .catch((e) => {
        setPensando(false);
        message.error("Algo no salio bien");
      });
  }

  const columns = [
    {
      title: "Titulo",
      dataIndex: "titulo",
      key: "titulo",
    },
    {
      title: "Descripcion",
      dataIndex: "descripcion",
      key: "descripcion",
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id, noti) => (
        <Popconfirm
          title="Eliminar esta empresa"
          onConfirm={() => {
            eliminarEmpresa(id, noti);
          }}
        >
          <strong style={{ cursor: "pointer" }}>X</strong>
        </Popconfirm>
      ),
    },
  ];
  return (
    <>
      <VerificarLogin />
      <Button
        onClick={() => {
          setVisible(true);
        }}
        style={{ marginBottom: 10 }}
      >
        Nueva Empresa
      </Button>
      <Drawer
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        title="Nueva Empresa"
      >
        <Form layout="vertical" onFinish={crearEmpresa}>
          <Form.Item
            name="titulo"
            label="Titulo"
            rules={[{ required: true, message: "Campo obligatorio" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="descripcion"
            label="Descripcion"
            rules={[
              { required: true, message: "Campo obligatorio" },
              { min: 100, message: "Minimo 100 caracteres" },
            ]}
          >
            <Input.TextArea min={100} />
          </Form.Item>
          <Button
            style={{ marginTop: 20 }}
            loading={pensando}
            htmlType="submit"
          >
            Guardar
          </Button>
        </Form>
      </Drawer>
      <Table dataSource={dataSource} columns={columns} />
    </>
  );
}
export default Cultura;
